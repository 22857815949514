<template>
    <div>
        <img src="../../assets/user-home/Horse.png">
        <p class="font-proxima-bold ml-3 text-secondary">Horse</p>
    </div>
</template>

<script>
export default {
    name: 'AnimalItems'
};
</script>

<style scoped>

</style>
